/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
class FreshUrl {
  static initClass() {
    this.libraries = {
      googleAnalytics: {
        present() {
          return window._gaq || window[window.GoogleAnalyticsObject];
        },
        ready(ready) {
          return FreshUrl.waitsFor(FreshUrl.libraries.googleAnalytics.present).then(() => {
            let ga;
            if ((ga = window._gaq)) {
              return ga.push(() => ready());
            }
            if ((ga = window[window.GoogleAnalyticsObject])) {
              return ga(() => ready());
            }
          });
        },
      },

      hubspot: {
        present() {
          return window._hsq || FreshUrl.scriptFrom(/\/\/(js\.hubspot\.com|js.hs-analytics\.net)/);
        },
        ready(ready) {
          return FreshUrl.waitsFor(() => window._hsq).then(() => window._hsq.push(() => ready()));
        },
      },

      clicky: {
        present() {
          return (
            window.clicky ||
            window.clicky_site_ids ||
            FreshUrl.scriptFrom(/\/\/static\.getclicky\.com/)
          );
        },
        ready(ready) {
          return FreshUrl.waitsFor(() => window.clicky_obj).then(ready);
        },
      },

      pardot: {
        present() {
          return window.piAId || window.piCId || FreshUrl.scriptContains(/\.pardot\.com\/pd\.js/);
        },
        ready(ready) {
          return FreshUrl.waitsFor(() =>
            __guard__(window.pi != null ? window.pi.tracker : undefined, (x) => x.url),
          ).then(ready);
        },
      },

      simplex: {
        present() {
          return window.simplex || FreshUrl.scriptFrom(/\/simplex\.js/);
        },
        ready(ready) {
          return FreshUrl.waitsFor(() => window.simplex).then(ready);
        },
      },

      analyticsJs: {
        present() {
          return window.analytics != null ? window.analytics.ready : undefined;
        },
        ready(ready) {
          return FreshUrl.waitsFor(() =>
            window.analytics != null ? window.analytics.ready : undefined,
          ).then(() => window.analytics.ready(ready));
        },
      },
    };

    // Save the original URL for convenience
    this.originalUrl = window.location.href;
  }

  // give this thing a list of strings and trigger functions to wait for
  // before cleaning the URL
  constructor(waitList) {
    // if the client doesn't support replaceState, don't bother with anything here.
    if (waitList == null) {
      waitList = [];
    }
    if (!window.history.replaceState) {
      return;
    }

    this.key = 0;
    this._isReady = {};

    for (let item of Array.from(waitList)) {
      if (typeof item === 'string' && FreshUrl.libraries[item]) {
        this.wait(FreshUrl.libraries[item].ready, item);
      } else if (typeof item === 'function') {
        this.wait(item);
      }
      if (typeof console !== 'undefined' && console !== null) {
        // eslint-disable-next-line no-console
        console.log(`FreshURL: Don't know how to wait for ${item}`);
      }
    }

    // if there's nothing to wait for, we're ready!
    if (waitList.length === 0) {
      if (this.allReady()) {
        this.allReadyCallback();
      }
    }

    // update all Wistia iframes
    FreshUrl.updateWistiaIframes();

    // listen for future Wistia iframes so we can make sure to update their pageUrls
    const iframeListener = function (event) {
      if (event.data === 'new-wistia-iframe') {
        return FreshUrl.updateWistiaIframes();
      }
    };
    if (typeof window !== 'undefined' && window !== null) {
      window.addEventListener('message', iframeListener, false);
    }
  }

  wait(trigger, key) {
    if (key == null) {
      key = this.nextKey();
    }
    this._isReady[key] = false;
    return trigger(() => this.ready(key));
  }

  ready(key) {
    this._isReady[key] = true;
    if (this.allReady()) {
      return this.allReadyCallback();
    }
  }

  // returns true if all the values in _analyticsReady are true
  allReady() {
    const notReady = [];
    for (let key in this._isReady) {
      let value = this._isReady[key];
      if (!value) {
        notReady.push(key);
      }
    }
    return notReady.length === 0;
  }

  allReadyCallback() {
    return window.history.replaceState(window.history.state, '', FreshUrl.cleanUrl());
  }

  static cleanUrl() {
    const cleanSearch = window.location.search
      .replace(/utm_[^&]+&?/g, '') // no UTM codes
      .replace(/(wkey|wemail)[^&]+&?/g, '') // no wkey, wemail
      .replace(/(_hsenc|_hsmi|hsCtaTracking)[^&]+&?/g, '') // no hubspot params
      .replace(/&$/, '')
      .replace(/^\?$/, '');

    return window.location.pathname + cleanSearch + window.location.hash;
  }

  static poll(cond, callback, interval, timeout) {
    if (interval == null) {
      interval = 50;
    }
    if (timeout == null) {
      timeout = 5000;
    }
    let pollTimeout = null;
    const start = new Date().getTime();
    let pollFn = function () {
      if (new Date().getTime() - start > timeout) {
        return;
      }
      if (cond()) {
        return callback();
      }
      clearTimeout(pollTimeout);
      return (pollTimeout = setTimeout(pollFn, interval));
    };

    return (pollTimeout = setTimeout(pollFn, 1));
  }

  // FreshUrl.waitsFor(conditionFn).then(callbackFn)
  static waitsFor(cond) {
    return {
      then(callback) {
        return FreshUrl.poll(cond, callback);
      },
    };
  }

  nextKey() {
    return (this.key += 1);
  }

  // FreshUrl.scriptFrom(/\/\/fast\.wistia\.com/)
  //
  // Returns true if there's a script whose src matches the supplied regex.
  static scriptFrom(re) {
    for (let script of Array.from(document.getElementsByTagName('script'))) {
      if (__guard__(script.getAttribute('src'), (x) => x.match(re))) {
        return true;
      }
    }
    return false;
  }

  // FreshUrl.scriptContains(/pardot\.com/)
  //
  // Returns true if there's a script block whose contents matches the supplied
  // regex.
  static scriptContains(re) {
    for (let script of Array.from(document.getElementsByTagName('script'))) {
      if (script.innerHTML != null ? script.innerHTML.match(re) : undefined) {
        return true;
      }
    }
    return false;
  }

  // Returns the names of libraries that it thinks are present on the page
  static librariesPresent() {
    return (() => {
      const result = [];
      for (let name in FreshUrl.libraries) {
        let library = FreshUrl.libraries[name];
        if (library.present()) {
          result.push(name);
        }
      }
      return result;
    })();
  }

  // Returns all Wistia iframes on the page
  static wistiaIframes() {
    return (() => {
      const result = [];
      for (let iframe of Array.from(document.getElementsByTagName('iframe'))) {
        if (iframe.src.match(/\/\/.*\.wistia\..*\//)) {
          result.push(iframe);
        }
      }
      return result;
    })();
  }

  // Posts the originalUrl to all Wistia iframes on the page
  static updateWistiaIframes() {
    const message = {
      method: 'updateProperties',
      args: [
        {
          params: { pageUrl: this.originalUrl },
          options: { pageUrl: this.originalUrl },
        },
      ],
    };

    return Array.from(this.wistiaIframes()).map((iframe) =>
      (() => {
        try {
          return iframe.contentWindow.postMessage(message, '*');
        } catch (e) {
          // nothing
        }
      })(),
    );
  }
}
FreshUrl.initClass();
// oh well, we tried

if (!window.FreshUrl) {
  window.FreshUrl = FreshUrl;
}

if (typeof _freshenUrlAfter !== 'undefined' && window._freshenUrlAfter !== null) {
  // oh, you want to wait for specific libraries, okay!
  window.freshUrl = new FreshUrl(window._freshenUrlAfter);
} else if (window.dataLayer) {
  // GTM is present, delay autodetection until after it's loaded
  window.dataLayer.push(() => (window.freshUrl = new FreshUrl(FreshUrl.librariesPresent())));
} else {
  // detect what's on the page, and wait for those libraries
  window.freshUrl = new FreshUrl(FreshUrl.librariesPresent());
}
function __guard__(value, transform) {
  return typeof value !== 'undefined' && value !== null ? transform(value) : undefined;
}
